/* eslint-disable no-use-before-define */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden, List, makeStyles } from '@material-ui/core';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { THEMES } from 'src/constants';
import { PATHS_AUTH, PATHS_DOC } from 'src/routes/paths';

const items = [
  {
    title: 'Welcome',
    href: PATHS_DOC.WELCOME,
  },
  {
    title: 'Postman',
    href: PATHS_DOC.POSTMAN,
  },
  {
    title: 'Getting Started',
    href: PATHS_DOC.GETTING_STARTED,
  },
  {
    title: 'API',
    href: PATHS_DOC.API,
  },
  {
    title: 'Authentication',
    href: PATHS_DOC.AUTHENTICATION,
  },
  {
    title: 'Me',
    href: PATHS_DOC.ME,
  },
  {
    title: 'Feedback',
    href: PATHS_DOC.FEEDBACK,
  },
  {
    title: 'Mail',
    href: PATHS_DOC.MAIL,
  },
  {
    title: 'Cache',
    href: PATHS_DOC.CACHE,
  },
  {
    title: 'Metadata',
    href: PATHS_DOC.METADATA,
  },
  {
    title: 'Localization',
    href: PATHS_DOC.LOCALIZATION,
  },
  {
    title: 'Promo Code',
    href: PATHS_DOC.PROMO_CODE,
  },
  {
    title: 'Ticket',
    href: PATHS_DOC.TICKET,
  },
  {
    title: 'Store',
    href: PATHS_DOC.STORE,
  },
  {
    title: 'Report',
    href: PATHS_DOC.REPORT,
  },
  {
    title: 'Realtime Notification',
    href: PATHS_DOC.REALTIME_NOTIFICATION,
  },
  {
    title: 'Video',
    href: PATHS_DOC.VIDEO,
  },
  {
    title: 'Lullaby',
    href: PATHS_DOC.LULLABY,
  },
  {
    title: 'Whitelist',
    href: PATHS_DOC.WHITELIST,
  },
  {
    title: 'Support',
    href: PATHS_DOC.SUPPORT,
  },
  {
    title: 'Changelog',
    href: PATHS_DOC.CHANGELOG,
  },
];

function renderNavItems({ items, depth = 0 }) {
  return <List disablePadding>{items.reduce((acc, item) => reduceChildRoutes({ acc, item, depth }), [])}</List>;
}

function reduceChildRoutes({ acc, item, depth = 0 }) {
  if (item.items) {
    acc.push(
      <NavItem depth={depth} key={item.href} title={item.title}>
        {renderNavItems({
          items: item.items,
          depth: depth + 1,
        })}
      </NavItem>,
    );
  } else {
    acc.push(<NavItem depth={depth} href={item.href} key={item.href} title={item.title} />);
  }

  return acc;
}

const useStyles = makeStyles(theme => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    paddingTop: 64,
  },
  logo: {
    height: 40,
  },
  logoBackground: {
    ...(theme.name === THEMES.LIGHT && {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main,
    }),
    ...(theme.name === THEMES.ONE_DARK && {
      backgroundColor: theme.palette.background.default,
    }),
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={2} className={classes.logoBackground}>
          <RouterLink to={PATHS_AUTH.ROOT}>
            <Logo className={classes.logo} />
          </RouterLink>
        </Box>
      </Hidden>
      <Box p={2}>{renderNavItems({ items })}</Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
