import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { Outlet } from 'react-router-dom';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    overflow: 'hidden',
    padding: '64px 0px 64px 256px',
  },
});

const DocsLayout = ({ children }) => {
  const classes = useStyles();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
      <div className={classes.wrapper}>{children}</div>
      <Outlet />
    </>
  );
};

DocsLayout.propTypes = {
  children: PropTypes.node,
};

export default DocsLayout;
