function path(root, sublink) {
  return `${root}${sublink}`;
}
function generatePaths(rootPath, sublink) {
  return {
    ROOT: path(rootPath, sublink),
    ADD: path(rootPath, `${sublink}/add`),
    EDIT: id => path(rootPath, `${sublink}/${id}`),
  };
}

const ROOT_AUTH = '/';
const ROOT_APP_OVERVIEW = '/app/overview';
const ROOT_APP_MANAGEMENT = '/app/management';
const ROOT_APP_APPLICATION = '/app/application';
const ROOT_APP_FORM = '/app/form';
const ROOT_DOCS = '/docs';

export const PATHS_AUTH = {
  ROOT: ROOT_AUTH,
  LOGIN: path(ROOT_AUTH, 'login'),
  REGISTER: path(ROOT_AUTH, 'register'),
};

export const ROOT_APP = '/app';

export const PATHS_APP_OVERVIEW = {
  ROOT: ROOT_APP_OVERVIEW,
  DASHBOARD: path(ROOT_APP_OVERVIEW, '/dashboard'),
  ACTIVITIES: path(ROOT_APP_OVERVIEW, '/activities'),
  TRANSACTIONS: path(ROOT_APP_OVERVIEW, '/transactions'),
};

export const PATHS_MANAGEMENT = {
  ROOT: ROOT_APP_MANAGEMENT,
  USERS: {
    ROOT: path(ROOT_APP_MANAGEMENT, '/users'),
    EDIT: userId => path(ROOT_APP_MANAGEMENT, `/users/${userId}`),
  },
  MEDIA: generatePaths(ROOT_APP_MANAGEMENT, '/media'),
  LANGUAGES: {
    ...generatePaths(ROOT_APP_MANAGEMENT, '/languages'),
    ADD_TRANSLATION: languageId => path(ROOT_APP_MANAGEMENT, `languages/${languageId}/translations/add`),
    EDIT_TRANSLATION: (languageId, translationId) =>
      path(ROOT_APP_MANAGEMENT, `languages/${languageId}/translations/${translationId}`),
  },
  PROMO_CODES: generatePaths(ROOT_APP_MANAGEMENT, '/promo-codes'),
  PRODUCTS: generatePaths(ROOT_APP_MANAGEMENT, '/products'),
  PRODUCT_PACKAGES: generatePaths(ROOT_APP_MANAGEMENT, '/product-packages'),
  CATEGORIES: generatePaths(ROOT_APP_MANAGEMENT, '/categories'),
  MOTOR_SKILLS: generatePaths(ROOT_APP_MANAGEMENT, '/motor-skills'),
  VIDEOS: generatePaths(ROOT_APP_MANAGEMENT, '/videos'),
  LULLABIES_AND_SONGS: generatePaths(ROOT_APP_MANAGEMENT, '/lullabies-and-songs'),
  GAME_TYPES: generatePaths(ROOT_APP_MANAGEMENT, '/game-types'),
  REMOTE_CONFIGS: generatePaths(ROOT_APP_MANAGEMENT, '/remote-configs'),
  WHITELIST_USERS: generatePaths(ROOT_APP_MANAGEMENT, '/whitelist-users'),
};

export const PATHS_APPLICATION = {
  ROOT: ROOT_APP_APPLICATION,
  LOGS: path(ROOT_APP_APPLICATION, '/logs'),
  TICKETS: {
    ROOT: path(ROOT_APP_APPLICATION, '/tickets'),
    EDIT: ticketId => path(ROOT_APP_APPLICATION, `/tickets/${ticketId}`),
  },
};

export const PATHS_FORM = {
  ROOT: ROOT_APP_FORM,
  FEEDBACK: path(ROOT_APP_FORM, '/feedback'),
  CONTACT: path(ROOT_APP_FORM, '/contact'),
  CAREER: path(ROOT_APP_FORM, '/career'),
};

export const PATHS_DOC = {
  ROOT: ROOT_DOCS,
  WELCOME: path(ROOT_DOCS, '/welcome'),
  GETTING_STARTED: path(ROOT_DOCS, '/getting-started'),
  POSTMAN: path(ROOT_DOCS, '/postman'),
  API: path(ROOT_DOCS, '/api'),
  AUTHENTICATION: path(ROOT_DOCS, '/authentication'),
  ME: path(ROOT_DOCS, '/me'),
  FEEDBACK: path(ROOT_DOCS, '/feedback'),
  MAIL: path(ROOT_DOCS, '/mail'),
  CACHE: path(ROOT_DOCS, '/cache'),
  METADATA: path(ROOT_DOCS, '/metadata'),
  LOCALIZATION: path(ROOT_DOCS, '/localization'),
  PROMO_CODE: path(ROOT_DOCS, '/promo-code'),
  TICKET: path(ROOT_DOCS, '/ticket'),
  STORE: path(ROOT_DOCS, '/store'),
  REPORT: path(ROOT_DOCS, '/report'),
  REALTIME_NOTIFICATION: path(ROOT_DOCS, '/realtime-notification'),
  VIDEO: path(ROOT_DOCS, '/video'),
  LULLABY: path(ROOT_DOCS, '/lullaby'),
  WHITELIST: path(ROOT_DOCS, '/whitelist'),
  // PRODUCT: path(ROOT_DOCS, '/product'),
  // PLATFORM: path(ROOT_DOCS, '/platform'),
  SUPPORT: path(ROOT_DOCS, '/support'),
  CHANGELOG: path(ROOT_DOCS, '/changelog'),
};
