import React, { lazy, Suspense } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import LoadingScreen from 'src/components/LoadingScreen';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';

const Loadable = Component => props => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  let element = useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to="/login" replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'ticket/:language/:ticketId',
          element: (
            <MainLayout>
              <TicketPlayer />
            </MainLayout>
          ),
        },
        {
          path: 'app',
          element: (
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          ),
          children: [
            {
              element: <Navigate to="/app/overview/dashboard" replace />,
              index: true,
            },
            {
              path: 'overview',
              children: [
                {
                  element: <Navigate to="/app/overview/dashboard" replace />,
                  index: true,
                },
                { path: 'dashboard', element: <Dashboard /> },
                { path: 'activities', element: <Activity /> },
                { path: 'transactions', element: <Transaction /> },
              ],
            },
            {
              path: 'management',
              children: [
                {
                  element: <Navigate to="/app/management/users" replace />,
                  index: true,
                },
                {
                  path: 'users',
                  element: <UserListView />,
                },
                {
                  path: 'users/:userId',
                  element: <UserDetailsView />,
                },
                {
                  path: 'media',
                  element: <MediaListView />,
                },
                { path: 'media/add', element: <MediaFormView /> },
                { path: 'media/:mediaId', element: <MediaFormView /> },

                {
                  path: 'languages',
                  element: <LanguageListView />,
                },
                { path: 'languages/add', element: <LanguageFormView /> },
                {
                  path: 'languages/:languageId',
                  element: <LanguageDetailsView />,
                },
                {
                  path: 'languages/:languageId/translations/add',
                  element: <TranslationFormView />,
                },
                {
                  path: 'languages/:languageId/translations/:translationId',
                  element: <TranslationFormView />,
                },

                {
                  path: 'promo-codes',
                  element: <PromoCodeListView />,
                },
                { path: 'promo-codes/add', element: <PromoCodeFormView /> },
                {
                  path: 'promo-codes/:promoCodeId',
                  element: <PromoCodeFormView />,
                },
                {
                  path: 'products',
                  element: <ProductListView />,
                },
                {
                  path: 'products/add',
                  element: <ProductFormView />,
                },
                {
                  path: 'products/:productId',
                  element: <ProductFormView />,
                },
                {
                  path: 'product-packages',
                  element: <ProductPackageListView />,
                },
                {
                  path: 'product-packages/add',
                  element: <ProductPackageFormView />,
                },
                {
                  path: 'product-packages/:productPackageId',
                  element: <ProductPackageFormView />,
                },
                {
                  path: 'categories',
                  element: <CategoryListView />,
                },
                { path: 'categories/add', element: <CategoryFormView /> },
                {
                  path: 'categories/:categoryId',
                  element: <CategoryFormView />,
                },
                {
                  path: 'motor-skills',
                  element: <MotorSkillListView />,
                },
                { path: 'motor-skills/add', element: <MotorSkillFormView /> },
                {
                  path: 'motor-skills/:skillId',
                  element: <MotorSkillFormView />,
                },
                {
                  path: 'videos',
                  element: <VideoListView />,
                },
                {
                  path: 'videos/add',
                  element: <VideoFormView />,
                },
                {
                  path: 'videos/:videoId',
                  element: <VideoEditView />,
                },
                {
                  path: 'lullabies-and-songs',
                  element: <LullabyAndSongListView />,
                },
                {
                  path: 'lullabies-and-songs/add',
                  element: <LullabyAndSongFormView />,
                },
                {
                  path: 'lullabies-and-songs/:lullabyId',
                  element: <LullabyAndSongEditView />,
                },
                {
                  path: 'game-types',
                  element: <GameListView />,
                },
                { path: 'game-types/add', element: <GameFormView /> },
                { path: 'game-types/:gameId', element: <GameFormView /> },
                {
                  path: 'remote-configs',
                  element: <RemoteConfigListView />,
                },
                {
                  path: 'remote-configs/add',
                  element: <RemoteConfigFormView />,
                },
                {
                  path: 'remote-configs/:remoteConfigId',
                  element: <RemoteConfigFormView />,
                },
                {
                  path: 'whitelist-users',
                  element: <WhitelistView />,
                },
                {
                  path: 'whitelist-users/add',
                  element: <WhitelistFormView />,
                },
                {
                  path: 'whitelist-users/:userId',
                  element: <WhitelistFormView />,
                },
              ],
            },
            {
              path: 'application',
              children: [
                {
                  element: <Navigate to="/app/application/logs" replace />,
                  index: true,
                },
                {
                  path: 'logs',
                  element: <LogListView />,
                },
                {
                  path: 'tickets',
                  element: <Ticket />,
                },
                { path: 'tickets/:ticketId', element: <Ticket /> },
              ],
            },
            {
              path: 'form',
              children: [
                {
                  element: <Navigate to="/app/form/feedback" replace />,
                  index: true,
                },
                {
                  path: 'feedback',
                  element: <Feedback />,
                },
                {
                  path: 'contact',
                  element: <ContactPage />,
                },
                {
                  path: 'career',
                  element: <CareerPage />,
                },
              ],
            },
            {
              path: 'account',
              element: <AccountView />,
            },
          ],
        },
        // {
        //   path: 'app/tickets',
        //   element: (
        //     <AuthGuard>
        //       <TicketLayout />
        //     </AuthGuard>
        //   ),
        //   children: [
        //     {
        //       path: '/app/tickets',
        //       element: <Ticket />,
        //       children: [
        //         {
        //           path: '/app/tickets/:ticketId',
        //           element: <Ticket />,
        //         },
        //       ],
        //     },
        //   ],
        // },
        {
          path: 'docs',
          element: <DocsLayout />,
          children: [
            { element: <Navigate to="/docs/welcome" replace />, index: true },
            { path: 'welcome', element: <WelcomeView /> },
            { path: 'getting-started', element: <GettingStartedView /> },
            { path: 'postman', element: <PostmanView /> },
            { path: 'api', element: <ApiView /> },
            { path: 'authentication', element: <AuthenticationView /> },
            { path: 'me', element: <MeView /> },
            { path: 'feedback', element: <FeedbackView /> },
            { path: 'mail', element: <MailView /> },
            { path: 'cache', element: <CacheView /> },
            { path: 'metadata', element: <MetadataView /> },
            { path: 'localization', element: <LocalizationView /> },
            { path: 'promo-code', element: <PromoCodeView /> },
            { path: 'ticket', element: <TicketView /> },
            { path: 'store', element: <StoreView /> },
            { path: 'report', element: <ReportView /> },
            { path: 'realtime-notification', element: <RealtimeNotificationView /> },
            { path: 'video', element: <VideoView /> },
            { path: 'lullaby', element: <LullabyView /> },
            { path: 'whitelist', element: <WhitelistDocView /> },
            { path: 'support', element: <SupportView /> },
            { path: 'changelog', element: <ChangelogView /> },
          ],
        },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: '404', element: <NotFoundView /> },
      ],
    },
  ]);
  return element;
}

// AUTH
const Login = Loadable(lazy(() => import('src/views/auth/LoginView')));
const Register = Loadable(lazy(() => import('src/views/auth/RegisterView')));
const NotFoundView = Loadable(lazy(() => import('src/views/errors/NotFoundView')));

// DASHBOARD
const TicketPlayer = Loadable(lazy(() => import('src/views/ticket/Player.js')));
const Dashboard = Loadable(lazy(() => import('src/views/overview/Dashboard')));
const Activity = Loadable(lazy(() => import('src/views/overview/Activity')));
const Transaction = Loadable(lazy(() => import('src/views/overview/Transaction')));
const UserListView = Loadable(lazy(() => import('src/views/users/UserListView')));
const UserDetailsView = Loadable(lazy(() => import('src/views/users/UserDetailsView')));
const MediaListView = Loadable(lazy(() => import('src/views/media/MediaListView')));
const MediaFormView = Loadable(lazy(() => import('src/views/media/MediaFormView')));
const LanguageListView = Loadable(lazy(() => import('src/views/language/LanguageListView')));
const LanguageDetailsView = Loadable(lazy(() => import('src/views/language/LanguageDetailsView')));
const LanguageFormView = Loadable(lazy(() => import('src/views/language/LanguageFormView')));
const TranslationFormView = Loadable(lazy(() => import('src/views/language/TranslationFormView')));
const PromoCodeListView = Loadable(lazy(() => import('src/views/promoCode/PromoCodeListView')));
const PromoCodeFormView = Loadable(lazy(() => import('src/views/promoCode/PromoCodeFormView')));
const ProductListView = Loadable(lazy(() => import('src/views/product/ProductListView')));
const ProductFormView = Loadable(lazy(() => import('src/views/product/ProductFormView')));
const ProductPackageListView = Loadable(lazy(() => import('src/views/productPackage/ProductPackageListView')));
const ProductPackageFormView = Loadable(lazy(() => import('src/views/productPackage/ProductPackageFormView')));
const Ticket = Loadable(lazy(() => import('src/views/ticket')));
const LogListView = Loadable(lazy(() => import('src/views/log/LogListView')));
const Feedback = Loadable(lazy(() => import('src/views/forms/Feedback')));
const ContactPage = Loadable(lazy(() => import('src/views/forms/ContactPage')));
const CareerPage = Loadable(lazy(() => import('src/views/forms/CareerPage')));
const AccountView = Loadable(lazy(() => import('src/views/account/AccountView')));
const CategoryListView = Loadable(lazy(() => import('src/views/category/CategoryListView')));
const CategoryFormView = Loadable(lazy(() => import('src/views/category/CategoryFormView')));
const MotorSkillListView = Loadable(lazy(() => import('src/views/motorSkill/MotorSkillListView')));
const MotorSkillFormView = Loadable(lazy(() => import('src/views/motorSkill/MotorSkillFormView')));
const VideoListView = Loadable(lazy(() => import('src/views/video/VideoListView')));
const VideoFormView = Loadable(lazy(() => import('src/views/video/VideoFormView')));
const VideoEditView = Loadable(lazy(() => import('src/views/video/VideoEditView')));
const LullabyAndSongListView = Loadable(lazy(() => import('src/views/lullabyAndSong/LullabyAndSongListView')));
const LullabyAndSongFormView = Loadable(lazy(() => import('src/views/lullabyAndSong/LullabyAndSongFormView')));
const LullabyAndSongEditView = Loadable(lazy(() => import('src/views/lullabyAndSong/LullabyAndSongEditView')));
const GameListView = Loadable(lazy(() => import('src/views/game/GameListView')));
const GameFormView = Loadable(lazy(() => import('src/views/game/GameFormView')));
const RemoteConfigListView = Loadable(lazy(() => import('src/views/remoteConfig/RemoteConfigListView')));
const RemoteConfigFormView = Loadable(lazy(() => import('src/views/remoteConfig/RemoteConfigFormView')));
const WhitelistView = Loadable(lazy(() => import('src/views/whitelist/WhitelistView')));
const WhitelistFormView = Loadable(lazy(() => import('src/views/whitelist/WhitelistFormView')));

// DOCS
const WelcomeView = Loadable(lazy(() => import('src/views/docs/WelcomeView')));
const GettingStartedView = Loadable(lazy(() => import('src/views/docs/GettingStartedView')));
const PostmanView = Loadable(lazy(() => import('src/views/docs/PostmanView')));
const ApiView = Loadable(lazy(() => import('src/views/docs/ApiView')));
const AuthenticationView = Loadable(lazy(() => import('src/views/docs/AuthenticationView')));
const MeView = Loadable(lazy(() => import('src/views/docs/MeView')));
const FeedbackView = Loadable(lazy(() => import('src/views/docs/FeedbackView')));
const MailView = Loadable(lazy(() => import('src/views/docs/MailView')));
const CacheView = Loadable(lazy(() => import('src/views/docs/CacheView')));
const MetadataView = Loadable(lazy(() => import('src/views/docs/MetadataView')));
const LocalizationView = Loadable(lazy(() => import('src/views/docs/LocalizationView')));
const StoreView = Loadable(lazy(() => import('src/views/docs/StoreView')));
const ReportView = Loadable(lazy(() => import('src/views/docs/ReportView')));
const RealtimeNotificationView = Loadable(lazy(() => import('src/views/docs/RealtimeNotificationView')));
const VideoView = Loadable(lazy(() => import('src/views/docs/VideoView')));
const LullabyView = Loadable(lazy(() => import('src/views/docs/LullabyView')));
const WhitelistDocView = Loadable(lazy(() => import('src/views/docs/WhitelistView')));
const PromoCodeView = Loadable(lazy(() => import('src/views/docs/PromoCodeView')));
const TicketView = Loadable(lazy(() => import('src/views/docs/TicketView')));
const SupportView = Loadable(lazy(() => import('src/views/docs/SupportView')));
const ChangelogView = Loadable(lazy(() => import('src/views/docs/ChangelogView')));
